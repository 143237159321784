import React from "react";
function App() {
  return (
    <div>
      <h1>Art da Sol </h1>
    </div>
  );
}

export default App;
